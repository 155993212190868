import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useEffect } from 'react'
import { AnalyticsEvent, trackEvent } from '../../../../../analytics/handlers'
import StellaVoucher from '../../../../../assets/Stella Voucher.png'

export const VoucherDialog = ({ showVoucher, setShowVoucher, currentUser, displayName }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (showVoucher) {
      trackEvent(AnalyticsEvent.voucherViewed, { currentUser: currentUser?.id?.uuid })
    }
  }, [showVoucher])

  return (
    <>
      {showVoucher && (
        <Dialog
          open={showVoucher}
          onClose={() => setShowVoucher(false)}
          fullWidth
          fullScreen={isMobile}
          scroll="body"
        >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              Your $100 Credit Voucher
              <IconButton onClick={() => setShowVoucher(false)}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <img
              src={StellaVoucher}
              alt="Stella Voucher"
              style={{
                width: '100%',
                maxHeight: 250,
                objectFit: 'contain',
                padding: 10,
                marginBottom: 16
              }}
            />

            <Typography>Redeem this voucher with participating practitioners</Typography>

            <Box
              marginTop={2}
              marginBottom={4}
              padding={2}
              display="flex"
              justifyContent="center"
              style={{
                border: '1px solid var(--marketplaceColor)',
                borderRadius: 4
              }}
            >
              <Typography variant="h4" style={{ color: 'var(--marketplaceColor)' }}>
                SSTART20X4
              </Typography>
            </Box>

            <Box marginTop={2} marginBottom={2}>
              <List subheader={<Typography variant="h6">How It Works</Typography>}>
                {displayName ? (
                  <>
                    <ListItem>
                      <ListItemText>
                        <Typography variant="body1">
                          1. Book a consultation with {displayName}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography variant="body1">2. Show this voucher on arrival</Typography>
                      </ListItemText>
                    </ListItem>
                  </>
                ) : (
                  <>
                    <ListItem>
                      <ListItemText>
                        <Typography variant="body1">
                          1. Find a practitioner on Stella that{' '}
                          <b style={{ color: 'var(--marketplaceColor)' }}>Accepts Stella Credit</b>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography variant="body1">
                          2. Book a consultation with the practitioner
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography variant="body1">3. Show this voucher on arrival</Typography>
                      </ListItemText>
                    </ListItem>
                  </>
                )}
              </List>
            </Box>

            <Box marginTop={2} marginBottom={3}>
              <Typography variant="caption">
                $50 off your 1st and 2nd sessions at participating practitioners. One per person,
                non-transferable, and single-use only. Additional practitioner terms may apply.
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
