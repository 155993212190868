import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { compose } from 'redux'

import { fetchPractitionerListings, selectListings, selectSortOption } from './duck'
import { useSearchPageStyles } from './hooks'

import { TopbarContainer } from '../TopbarContainer'

import Page from '../../../components/Page/Page'
import { GetCreditCard } from '../../../components/mui/GetCreditCard'
import { PractitionerListingsSection } from '../../../components/mui/PractitionerListingsSection'
import { PractitionerSearchSection } from '../../../components/mui/PractitionerSearchSection'
import { isScrollingDisabled } from '../../../ducks/ui.duck'
import { selectCurrentUser } from '../../../ducks/user.duck'

const SearchPageComponent = ({ currentUser, listings, sortOption, scrollingDisabled }) => {
  const dispatch = useDispatch()
  const [currentView, setCurrentView] = useState('list')
  const { classes } = useSearchPageStyles()

  useEffect(() => {
    dispatch(fetchPractitionerListings())
  }, [sortOption, currentView])

  return (
    <Page className={classes.container} scrollingDisabled={scrollingDisabled}>
      <TopbarContainer currentPage="NewSearchPage" />
      <Box className={classes.listingsOuterContainer}>
        <Box className={classes.listingsInnerContainer}>
          <GetCreditCard currentUser={currentUser} />
          <PractitionerSearchSection setCurrentView={setCurrentView} />
          <PractitionerListingsSection listings={listings} currentView={currentView} />
        </Box>
      </Box>
    </Page>
  )
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  listings: selectListings(state),
  sortOption: selectSortOption(state),
  scrollingDisabled: isScrollingDisabled(state)
})

const SearchPage = compose(connect(mapStateToProps))(SearchPageComponent)

export default SearchPage
